input[type="radio"] {
    display: none;
}

label {
    color: white;
    z-index: 10;
    cursor: pointer;
    text-align: center;
    font-family: 'SFHeavy';
}

h4 {
    margin: 0;
    font-weight: 500;
    color: white;
    font-family: 'SFHeavy';
    line-height: normal;
    letter-spacing: 2px;
    text-align: center
}



.main-container {
    display: inline-block;
    vertical-align: middle;
    width: 60%;
    height: 40px;
    border-radius: 100px;
    background-color: #e3e3e3;
    top: 8%;
    left: 22%;
}

.switch {
    height: 37px;
    width: 50%;
    background-color: #243f7d;
    border-radius: 100px;
    position: absolute;
    left: 1px;
    top: 1px;
    transition: ease-in;
}

/*---------------- LEFT ------------------- */


.switch.left-to-right {
    animation-name: leftToRight;
    animation-duration: 0.5s;
}

.switch.left-position {
    right: 3px;
}

.left-label {
    position: absolute;
    left: 2px;
}

#left+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 50%; 
    border-radius: 100px;
    font-family: 'SFHeavy';    
}

#left:checked+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 50%; 
    border-radius: 100px;
    font-family: 'SFHeavy';
}



@keyframes leftToRight {
    from {
        left: 3px;
    }

    to {
        left: 50%;
    }
}

/* -------------------- center ------------------ */


/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
    animation-name: rightToLeft;
    animation-duration: 0.5s;
}

.switch.right-to-center {
    animation-name: rightToCenter;
    animation-duration: 0.5s;
}

.switch.right-position {
    left: 50%;
}

.right-label {
    position: absolute;
    right: 2px;
}

#right+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 50%; 
    border-radius: 100px;
}

#right:checked+label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 50%; 
    border-radius: 100px;
    font-family: 'SFHeavy';
}

@keyframes rightToLeft {
    from {
        left: 167px;
    }

    to {
        left: 3px;
    }
}

@keyframes rightToCenter {
    from {
        left: 167px;
    }

    to {
        right: 85px;
    }
}