.container {
    background-color: #12377f;
    min-height: 100vh
}

.main-logo {
    width: 50%;
    cursor: pointer;
    margin-left: 6%;
}

.main-logo-mini {
    width: 45%;
    cursor: pointer;
    margin-right: 6%;
}

.main-logo-ikili {
    width:150%;
    cursor: pointer;


}

.social-logo {
    width: 70%;
    cursor: pointer;
}

.footer-mobile-app {
    background-color: white;
    height: 80%;
}

.content {
    background-color: white;
    margin-top: 110px;
}

.footer-date {
    color: white;
    font-family: 'SFBold';
    line-height: normal;
    font-size: large;
    letter-spacing: 6px;
    text-align: center;
}

.footer-location {
    color: white;
    line-height: normal;
    font-weight: 300 500;
    font-size: 16PX;
    text-align: center;
    letter-spacing: 4px;
}

.footer-rigth {
    color: white;
    font-family: 'SFRegular';
    line-height: normal;
    font-weight: 300 500;
    font-size: 0.6vw;
    line-height: 24px;
    text-align: right;
}

.menu-item {
    color: white;
    font-family: 'SFRegular';
    text-align: center;
    font-size: 18px;
}

.menu-item-hamburger {
    font-family: 'SFRegular';
    text-align: justify;
    font-size: 16px;
    color: #12377f;
}

.menu-item-col {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.active {
    font-family: 'SFBlack';
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.fadeOut {
    /* animation: 0.5s dalga2 forwards;  */
}

.fadeIn {
    /* animation: 0.5s fadeOut forwards;  */
}

@keyframes dalga2 {
    from {
        transform: translate(-40%, 0);
    }

    to {
        transform: translate(0%, 0);
    }

}



@keyframes fadeOut {
    from {
        transform: translate(0%, 0);
    }

    to {
        transform: translate(0%, 0);
    }
}

@media screen and (max-width: 1366px) and (orientation:landscape) {}

@media screen and (max-width: 1367px) and (max-height: 1080px) {}
@media screen and (max-width: 1290px) {
    /* .footer-rigth{
font-size: 8px;
line-height: 24px;
    } */
}
@media screen and (max-width: 1024px) {}



@media screen and (max-width: 1024px) and (orientation:portrait) {}

@media screen and (max-width: 952px) {
    .main-logo {
        width: 80%;
        cursor: pointer;
    }

    .main-logo-mini {
        width: 65%;
        cursor: pointer;
    }

    .footer-date {
        font-size: 10px;
        letter-spacing: 1px;
    }

    .footer-location {
        font-size: 10px;
    }

    .ant-layout-footer {
        padding: 2% !important;
    }

}

@media screen and (max-width: 952px)and (orientation: landscape) {
    .main-logo {
        width: 70%;
        cursor: pointer;
    }

    .main-logo-mini {
        width: 65%;
        cursor: pointer;
    }

}

@media screen and (max-width: 850px) {
    .main-logo {
        width: 80%;
        cursor: pointer;
    }

    .main-logo-mini {
        width: 75%;
        cursor: pointer;
    }

    .footer-date {
        font-size: 10px;
        letter-spacing: 1px;
    }

    .footer-location {
        font-size: 10px;
    }

    .ant-layout-footer {
        padding: 2% !important;
    }

}

@media screen and (max-width: 850px)and (orientation: landscape) {
    .main-logo {
        width: 70%;
        cursor: pointer;
    }

    .main-logo-mini {
        width: 70%;
        cursor: pointer;
    }
    .main-logo-ikili {
        width:100%;
        cursor: pointer;
    
    
    }
}

@media screen and (max-width: 812px) {}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 768px) and (orientation:landscape) {
    .main-logo {
        width: 45%;
    }

    .main-logo-mini {
        width: 40%;
    }

    .ant-layout-header {
        height: 90px !important;
    }

    .content {
        background-color: white;
        margin-top: 90px;
    }
}

@media screen and (max-width: 576px) {
    .main-logo {
        width: 70%;
        cursor: pointer;
    }

    .main-logo-mini {
        width: 60%;
        cursor: pointer;
    }

    .footer-date {
        font-size: 7px;
        letter-spacing: 1px;
    }

    .footer-location {
        font-size: 7px;
    }

    .footer-rigth {
        display: none
    }

    .ant-layout-footer {
        padding: 2% !important;
    }

    .ant-layout-header {
        height: 80px !important;
    }

    .content {
        background-color: white;
        margin-top: 80px;
    }
}

@media screen and (max-width: 411px) {


    .main-logo-mini {
        display: none;
    }

    .footer-date {
        font-size: 7px;
        letter-spacing: 1px;
    }

    .footer-location {
        font-size: 7px;
    }

    .footer-rigth {
        display: none
    }

    .ant-layout-footer {
        padding: 2% !important;
    }

    .ant-layout-header {
        height: 80px !important;
    }

    .content {
        background-color: white;
        margin-top: 80px;
    }
}