@font-face {
  font-family: 'SFHeavy';
  src: local('SFHeavy'), url(./assets/fonts/SF-Pro-Text-Heavy.otf) format('opentype');
}

@font-face {
  font-family: 'SFRegular';
  src: local('SFRegular'), url(./assets/fonts/SF-Pro-Text-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'SFBlack';
  src: local('SFBlack'), url(./assets/fonts/SF-Pro-Text-Black.otf) format('opentype');
}

@font-face {
  font-family: 'SFMedium';
  src: local('SFMedium'), url(./assets/fonts/SF-Pro-Text-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'SFBold';
  src: local('SFBold'), url(./assets/fonts/SF-Pro-Text-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'SFSemibold';
  src: local('SFSemibold'), url(./assets/fonts/SF-Pro-Text-Semibold.otf) format('opentype');
}





body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Regular''Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.margin1 {
  margin: 0.5em;
}

.margin2 {
  margin: 1em;
}

.margin3 {
  margin: 1.5em;
}

.margin4 {
  margin: 2em;
}

.marginB1 {
  margin-bottom: 0.5em;
}

.marginB2 {
  margin-bottom: 1em;
}

.marginB3 {
  margin-bottom: 1.5em;
}

.marginB4 {
  margin-bottom: 2em;
}

.marginT1 {
  margin-top: 0.5em;
}

.marginT2 {
  margin-top: 1em;
}

.marginT3 {
  margin-top: 1.5em;
}

.marginT4 {
  margin-top: 2em;
}

.marginR1 {
  margin-right: 0.5em;
}

.marginR2 {
  margin-right: 1em;
}

.marginR3 {
  margin-right: 1.5em;
}

.marginR4 {
  margin-right: 2em;
}

.marginR5 {
  margin-right: 2.5em;
}

.marginL1 {
  margin-left: 0.5em;
}

.marginL2 {
  margin-left: 1em;
}

.marginL3 {
  margin-left: 1.5em;
}

.marginL4 {
  margin-left: 2em;
}

.marginL5 {
  margin-left: 2.5em;
}

.padding1 {
  padding: 0.5em;
}

.padding2 {
  padding: 1em;
}

.padding3 {
  padding: 1.5em;
}

.padding4 {
  padding: 2em;
}

.paddingB1 {
  padding-bottom: 0.5em;
}

.paddingB2 {
  padding-bottom: 1em;
}

.paddingB3 {
  padding-bottom: 1.5em;
}

.paddingB4 {
  padding-bottom: 2em;
}

.paddingT1 {
  padding-top: 0.5em;
}

.paddingT2 {
  padding-top: 1em;
}

.paddingT3 {
  padding-top: 1.5em;
}

.paddingT4 {
  padding-top: 2em;
}

.paddingT8 {
  padding-top: 4em;
}

.paddingR1 {
  padding-right: 0.5em;
}

.paddingR2 {
  padding-right: 1em;
}

.paddingR3 {
  padding-right: 1.5em;
}

.paddingR4 {
  padding-right: 2em;
}

.paddingR5 {
  padding-right: 2.5em;
}

.paddingL1 {
  padding-left: 0.5em;
}

.paddingL2 {
  padding-left: 1em;
}

.paddingL3 {
  padding-left: 1.5em;
}

.paddingL4 {
  padding-left: 2em;
}

.paddingL5 {
  padding-left: 2.5em;
}

.paddingL10 {
  padding-left: 5em;
}

.paddingL20 {
  padding-left: 10em;
}

.paddingL40 {
  padding-left: 20em;
}

.alignR {
  text-align: right;
}

.alignC {
  text-align: center;
}

.alignL {
  text-align: left;
}

.absolute {
  position: absolute;
  z-index: 4;
}

.boldFont {
  font-weight: bold;
}

.fill {
  width: 100%;
  height: 100%;
}

.fill-width {
  width: 100%;
}

.fill-height {
  height: 100%;
}

.ant-layout {
  height: max-content;
  min-height: 100vh !important;
}

.ant-layout-header {
  background: #12377f !important;
  padding: 0 !important;
  height: 110px !important;
}

.ant-layout-footer {
  background: #12377f !important;
  height: 5% !important;
  padding-top: 1% !important
}

.ant-carousel .slick-dots li button {
  display: block;
  width: 10px !important;
  border-radius: 50% !important;
  height: 10px !important;
}

.ant-carousel .slick-dots li.slick-active button {
  width: 15px !important;
  height: 15px !important;
  margin-bottom: 10px;
}
.ant-divider-horizontal {
  margin: 4px 0 !important;
}
.ant-modal-content{
  background-color: rgb(0, 0, 0,0.5) !important;
}
.ant-modal-close{
  color: white !important;
}