.btn-container {
    height: 25vh;
    text-align: center;
    border: 1px solid #c3c3c5;
    cursor: pointer;
}
.btn-container-little {
    height: 20vh;
  
}

.action-text {
    color: #12377f;
    font-family: 'SFBold';
    line-height: normal;
    font-size: 25px;
}

.hakkinda {
    position: absolute;
    left: 22%;
    top: 25%;
}

.program {
    position: absolute;
    left: 42%;
    bottom: 5%;
}

.speakers {
    position: absolute;
    left: 48%;
    bottom: 5%;
}

.deneyim {
    position: absolute;
    right: 22%;
    top: 25%;
}

.hakkinda-kule {
    height: 70%;
    position: absolute;
    bottom: 12%;
    left: 5%;
}

.hakkinda-footer {
    height: 15%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    background-color: #12377f;
}

.hakkinda-gemi {
    height: 25%;
    position: absolute;
    bottom: 21%;
    right: 5%;
}



.hakkinda-marti-1 {
    height: 5%;
    position: absolute;
    top: 37%;
    right: 32%;


}

.hakkinda-marti-2 {
    height: 5%;
    position: absolute;
    top: 27%;
    right: 35%;
}

.hakkinda-marti-3 {
    height: 5%;
    position: absolute;
    top: 35%;
    right: 40%;
}


.dumen-kenar {
    height: 70%;
    position: absolute;
    bottom:0%;
    left: 0%;
}

.dumen {
    height: 50%;
    position: absolute;
    top: 10%;
    right: 35%;
}

.speakers-left {
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
}

.speaker-table {
    height: 40%;
    position: absolute;
    top: 10%;
    right: 30%;
}

.capa {
    height: 55%;
    position: absolute;
    top: 10%;
    left: 5%;
}

.dalga-1 {
    height: 7%;
    position: absolute;
    bottom: 15%;
    right: 10%;
}

.dalga-2 {
    height: 7%;
    position: absolute;
    bottom: 25%;
    right: 7%;
}

.deneyim-footer {
    height: 15%;
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 30%;
    background-color: #12377f;
}

.btn-container:hover {
    background-color: #12367f17;
}

.btn-container:hover>.hakkinda-gemi {
    animation: gemi 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 1;
}

.btn-container:hover>.hakkinda-marti-1 {
    animation: marti1 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 1;
}

.btn-container:hover>.hakkinda-marti-2 {
    animation: marti2 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 1;
}

.btn-container:hover>.hakkinda-marti-3 {
    animation: marti3 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 3s;
}

.btn-container:hover>.dumen {
    animation: dumen 8s infinite linear;
    transform: scale(1.8);
}

.btn-container:hover>.dalga-1 {
    animation: dalga1 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
}

.btn-container:hover>.dalga-2 {
    animation: dalga2 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 3s;
}

.btn-container:hover>.capa {
    animation: pendulum 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 4s;
}

.btn-container:hover>.speaker-table {
    animation: shake 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 2s;
    height: 60%;
}

@keyframes dumen {
    from {
        transform: rotate(359deg);
    }

    to {
        transform: rotate(0deg);
    }

}

@keyframes marti1 {
    from {
        transform: translate(0%, 0%);
    }

    to {
        transform: translate(100%, -100%);
    }

}

@keyframes marti2 {
    from {
        transform: translate(0%, 0%);
    }

    to {
        transform: translate(-100%, 100%);
    }

}

@keyframes marti3 {
    from {
        transform: translate(0%, 0);
    }

    to {
        transform: translate(-200%, 100%);
    }

}

@keyframes gemi {
    from {
        transform: translate(0%, 0);
    }

    to {
        transform: translate(-55%, 0);
    }

}

@keyframes dalga1 {
    from {
        transform: translate(0%, 0);
    }

    to {
        transform: translate(-25%, 0);
    }

}

@keyframes dalga2 {
    from {
        transform: translate(0%, 0);
    }

    to {
        transform: translate(25%, 0);
    }

}

@keyframes pendulum {
    0% {
        transform: rotate(0deg);
    }

    20% {
        transform: rotate(-30deg);
    }

    50% {
        transform: rotate(30deg);
    }

    80% {
        transform: rotate(-30deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@media screen and (max-width: 1366px) and (orientation:landscape) {}

@media screen and (max-width: 1367px) and (max-height: 1080px) {}

@media screen and (max-width: 1024px) {}

@media screen and (max-width: 1024px) and (orientation:portrait) {}

@media screen and (max-width: 952px) {
    .action-text {
        color: #12377f;
        font-family: 'SFBold';
        line-height: normal;
        font-size: 40px;
    }

    .hakkinda {
        position: absolute;
        left: 30%;
        top: 5%;
    }


    .dumen {
        height: 50%;
        position: absolute;
        top: 10%;
        right: 5%;
    }

    .program {
        position: absolute;
        left: 52%;
        bottom: 20%;
    }

    .speaker-table {
        height: 35%;
        position: absolute;
        bottom: 1%;
        right: 15%;
    }

    .speakers {
        position: absolute;
        left: 28%;
        top: 45%;
    }
    .dalga-1 {
        height: 5%;
        position: absolute;
        bottom: 10%;
        right: 20%;
    }
    
    .dalga-2 {
        height: 5%;
        position: absolute;
        bottom: 18%;
        right: 7%;
    }
    .capa {
        height: 45%;
    }
    .deneyim {
        position: absolute;
        right: 5%;
        top: 25%;
    }
    

}
@media screen and (max-width: 952px)and (orientation: landscape) {
    .action-text {
        color: #12377f;
        font-family: 'SFBold';
        line-height: normal;
        font-size: 30px;
    }

    .hakkinda {
        position: absolute;
        left: 20%;
        top: 40%;
    }


    .dumen {
        height: 60%;
        position: absolute;
        top: 10%;
        left: 20%;
    }

    .program {
        position: absolute;
        left: 52%;
        bottom: 20%;
    }

    .speaker-table {
        height: 45%;
        position: absolute;
        top: 25%;
        right: 15%;
    }

    .speakers {
        position: absolute;
        left: 28%;
        top: 45%;
    }
    .dalga-1 {
        height: 14%;
        position: absolute;
        bottom: 5%;
        right: 20%;
    }
    
    .dalga-2 {
        height: 14%;
        position: absolute;
        bottom: 18%;
        right: 7%;
    }
    .capa {
        height: 65%;
    }
    .deneyim {
        position: absolute;
        right: 5%;
        top: 25%;
    }
    

}
@media screen and (max-width: 850px) {
    .action-text {
        color: #12377f;
        font-family: 'SFBold';
        line-height: normal;
        font-size: 40px;
    }

    .hakkinda {
        position: absolute;
        left: 30%;
        top: 5%;
    }


    .dumen {
        height: 50%;
        position: absolute;
        top: 10%;
        right: 5%;
    }

    .program {
        position: absolute;
        left: 52%;
        bottom: 20%;
    }

    .speaker-table {
        height: 35%;
        position: absolute;
        bottom: 1%;
        right: 15%;
    }

    .speakers {
        position: absolute;
        left: 28%;
        top: 45%;
    }
    .dalga-1 {
        height: 5%;
        position: absolute;
        bottom: 10%;
        right: 20%;
    }
    
    .dalga-2 {
        height: 5%;
        position: absolute;
        bottom: 18%;
        right: 7%;
    }
    .capa {
        height: 45%;
    }
    .deneyim {
        position: absolute;
        right: 5%;
        top: 25%;
    }
    

}
@media screen and (max-width: 851px)and (orientation: landscape) {
    .action-text {
        color: #12377f;
        font-family: 'SFBold';
        line-height: normal;
        font-size: 30px;
    }

    .hakkinda {
        position: absolute;
        left: 20%;
        top:45%;
    }


    .dumen {
        height:70%;
        position: absolute;
        top: 10%;
        right: 5%;
    }

    .program {
        position: absolute;
        left: 52%;
        bottom: 20%;
    }

    .speaker-table {
        height: 55%;
        position: absolute;
        bottom: 1%;
        right: 10%;
    }

    .speakers {
        position: absolute;
        left: 28%;
        top: 45%;
    }
    .dalga-1 {
        height: 14%;
        position: absolute;
        bottom:5%;
        right: 20%;
    }
    
    .dalga-2 {
        height: 14%;
        position: absolute;
        bottom: 18%;
        right: 7%;
    }
    .capa {
        height: 75%;
    }
    .deneyim {
        position: absolute;
        right: 5%;
        top: 25%;
    }
}
@media screen and (max-width: 812px) {}

@media screen and (max-width: 768px) {

    
}
@media screen and ( max-width: 768px   ) and (orientation: landscape) {
    .action-text {
        color: #12377f;
        font-family: 'SFBold';
        line-height: normal;
        font-size: 29px;
    }

    .hakkinda {
        position: absolute;
        left: 18%;
        top: 5%;
    }

    .hakkinda-gemi {
        height: 35%;
        position: absolute;
        bottom: 21%;
        right: 4%;
    }

    .dumen {
        height: 60%;
        position: absolute;
        top: 10%;
        left: 28%;
    }

    .program {
        position: absolute;
        left: 54%;
        bottom: 30%;
    }

    .speaker-table {
        height: 56%;
        position: absolute;
        bottom: 2%;
        right: 10%;
    }

    .speakers {
        position: absolute;
        left: 13%;
        top: 35%;
    }
    .dalga-1 {
        height: 11%;
        position: absolute;
        bottom: 7%;
        right: 20%;
    }
    
    .dalga-2 {
        height: 11%;
        position: absolute;
        bottom: 18%;
        right: 7%;
    }
    .capa {
        height: 65%;
    }
    .deneyim {
        position: absolute;
        right: 5%;
        top: 25%;
    }
    
}

@media screen and (max-width: 576px) {
    .action-text {
        color: #12377f;
        font-family: 'SFBold';
        line-height: normal;
        font-size: 20px;
    }

    .hakkinda {
        position: absolute;
        left: 30%;
        top: 5%;
    }

    .hakkinda-gemi {
        height: 15%;
        position: absolute;
        bottom: 21%;
        right: 4%;
    }

    .dumen {
        height: 30%;
        position: absolute;
        top: 10%;
        right: 2%;
    }

    .program {
        position: absolute;
        left: 52%;
        bottom: 30%;
    }

    .speaker-table {
        height: 24%;
        position: absolute;
        bottom: 1%;
        right: 15%;
    }

    .speakers {
        position: absolute;
        left: 28%;
        top: 37%;
    }
    .dalga-1 {
        height: 4%;
        position: absolute;
        bottom: 10%;
        right: 20%;
    }
    
    .dalga-2 {
        height: 4%;
        position: absolute;
        bottom: 18%;
        right: 7%;
    }
    .capa {
        height: 45%;
    }
    .deneyim {
        position: absolute;
        right: 5%;
        top: 25%;
    }
    
}


@media screen and (max-width: 411px) {
    .action-text {
        color: #12377f;
        font-family: 'SFBold';
        line-height: normal;
        font-size: 20px;
    }

    .hakkinda {
        position: absolute;
        left: 30%;
        top: 5%;
    }

    .hakkinda-gemi {
        height: 20%;
        position: absolute;
        bottom: 21%;
        right: 4%;
    }

    .dumen {
        height: 40%;
        position: absolute;
        top: 10%;
        right: 2%;
    }

    .program {
        position: absolute;
        left: 52%;
        bottom: 30%;
    }

    .speaker-table {
        height: 24%;
        position: absolute;
        bottom: 1%;
        right: 20%;
    }

    .speakers {
        position: absolute;
        left: 25%;
        top: 37%;
    }
    .dalga-1 {
        height: 4%;
        position: absolute;
        bottom: 10%;
        right: 20%;
    }
    
    .dalga-2 {
        height: 4%;
        position: absolute;
        bottom: 18%;
        right: 7%;
    }
    .capa {
        height: 45%;
    }
    .deneyim {
        position: absolute;
        right: 5%;
        top: 25%;
    }
}
@media screen and (max-width: 393px) {
 
    .hakkinda-gemi {
        height: 15%;
        position: absolute;
        bottom: 21%;
        right: 4%;
    }

    .dumen {
        height: 32%;
        position: absolute;
        top: 10%;
        right: 2%;
    }

    .program {
        position: absolute;
        left: 52%;
        bottom: 30%;
    }

    .speaker-table {
        height: 24%;
        position: absolute;
        bottom: 1%;
        right: 18%;
    }

    .speakers {
        position: absolute;
        left: 28%;
        top: 37%;
    }
    .action-text {
        font-size: 18px;
    }
}