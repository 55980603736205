.carousel {
    background: transparent;
    height: 100% !important;
}

.carousel-item {
    background: transparent;
    height: 50vh !important;
    text-align: center;
    width: 100%;
    padding-top: 1%;
    background-image: url(../assets/images/banner1.png);
    background-size: cover;
}

.carousel-item-content {
    width: 100%;
    text-align: center;
    height: 100%;
}

.banner1 {
    background-repeat: no-repeat;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
    background-image: url(../assets/images/banner1.png);
    height: 100%;
}

.banner1-mobile {
    background-repeat: no-repeat;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
    background-image: url(../assets/images/banner1-mobile.png);
    height: 100%;
}

.banner2 {
    background-repeat: no-repeat;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
    background-image: url(../assets/images/banner2.png);
}

.banner3 {
    background-repeat: no-repeat;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
    background-image: url(../assets/images/banner3.png);
}

.banner4 {
    background-repeat: no-repeat;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
    background-image: url(../assets/images/banner4.png);
}
.banner5 {
    background-repeat: no-repeat;
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: cover;
    /* Resize the background image to cover the entire container */
    background-image: url(../assets/images/banner5.png);
}
.play-icon{
    width: 5%;
    cursor: pointer;
}
.play-icon:hover{
    -webkit-animation:fontbulger 2s infinite;
   -moz-animation:fontbulger 2s infinite;
   -o-animation:fontbulger 2s infinite;
   animation:fontbulger 2s infinite;
}

@-webkit-keyframes fontbulger  {
    0%, 100% {
        width: 6%;
    }
    
    50% {
        width: 7%;
    }
 
 }
 
 @-moz-keyframes fontbulger  {
    0%, 100% {
        width: 6%;
    }
    
    50% {
        width: 7%;
    }
 
 }
 
 @-o-keyframes fontbulger  {
    0%, 100% {
        width: 6%;
    }
    
    50% {
        width: 7%;
    }
 
 }
 
 @keyframes fontbulger  {
    0%, 100% {
        width: 6%;
    }
    
    50% {
        width: 7%;
    }
 
 }
.carousel-item h2 {
    color: white;
    font-family: 'SFBold';
    font-weight: bold;
    line-height: normal;
    font-size: 35px;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 5%;
}

.carousel-item h4 {
    color: white;
    font-family: 'SFRegular';
    line-height: normal;
    font-size: 30px;
    padding-left: 15%;
    padding-right: 15%;
}

.carousel-item h5 {
    color: white;
    font-size: 15px;
    font-weight: 300;
    line-height: normal;
    font-family: 'SFRegular';
    padding-left: 30%;
    padding-right: 30%;
    text-align: center;
    line-height: 22px;
}

.carousel-extra {
    font-size: 18px !important;
    line-height: 30px !important;
}

.carousel-container {
    height: 70%;
    background-color: #12377f;
}

.action-container {
    background-color: white;
    padding-top: 1%;
    padding-bottom: 1%;
}

.header-banner {
    width: 100%;
}

.banner-content {
    background-color: rgb(18, 55, 127, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    color: white;
    font-family: 'SFBold';
    font-size: 50px;
    padding-top: 2.5%;
}

.about-content {
    padding-top: 2%;
}

.about-content h1 {
    color: #243F7D;
    font-family: 'SFHeavy';
    font-size: 50px;
    text-align: center;
    height: 15%;
}

.about-content h2 {
    color: #243F7D;
    font-family: 'SFRegular';
    font-size: 40px;
    text-align: center;
    font-weight: 100;
}

.about-content h4 {
    padding-right: 15%;
    padding-left: 15%;
    padding-top: 1%;
    font-family: 'SFRegular';
    font-size: 16px;
    text-align: center;
    letter-spacing: 0px;
    line-height: 30px;
}

.speaker-main-content {
    padding-right: 10%;
    padding-left: 10%;
}

.speaker-item {
    padding: 1%
}



.speaker-item-container {
    background-color: gray;
}

.spekars-footer {
    height: 70px;
    width: 100%;
    background-color: #243f7d;
    padding: 3%;
}

.speaker-image {
    width: 100%;
}
.reis {
    padding-left: 30%;
    padding-right: 30%;
}


.speaker-name {
    color: white;
    font-family: 'SFBold';
    font-weight: bold;
    line-height: normal;
    font-size: 18px;
    text-align: center;
    padding-top: 1%;
}

.speaker-title {
    color: white;
    font-family: 'SFRegular';
    line-height: normal;
    font-size: 10px;
    text-align: center;
    padding-top: 1%;
    letter-spacing: 2px;
}

.speakers-content {
    text-align: center;
    font-family: 'SFSemibold';
    color: #243f7d;
    font-size: 18px;
    letter-spacing: 1px;
}

.speakers-banner {
    width: 30%;
    margin-top: 2%;
}

.schedular {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 4%;
}

.scheduler-time {
    color: #243F7D;
    font-family: 'SFBold';
    font-size: 16px;
    text-align: center;
    letter-spacing: -1px;
    border-right: 1px solid #979797;
    border-bottom: 1px solid #979797;
    padding-top: 2%;
    padding-right: 2%;
    box-shadow: 1px 1px #979797;
}

.scheduler-header {
    color: #1e1e1e;
    font-family: 'SFBold';
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    letter-spacing: 1.5px;
    border-bottom: 1px solid #979797;
    box-shadow: 0px 1px #979797;
    padding: 2%;
}

.collapse-icon {
    width: 50%;
    cursor: pointer;
    margin-top: -15%;
    position: absolute;
}

.scheduler-desc {
    color: #1e1e1e;
    font-size: 14px;
    font-family: 'SFRegular';
    letter-spacing: 1px;
    padding-top: 2%;
    text-align: left;
    letter-spacing: 0px;
    line-height: 30px;
}

.scheduler-subheader {
    color: #020202;
    font-size: 14px;
    font-family: 'SFBold';
    letter-spacing: 0.5px;
    font-weight: bold;
    padding-top: 2%;
}

.titles {
    color: #1e1e1e;
    font-size: 14px;
    font-family: 'SFRegular';
    padding-top: 2%;
    text-align: left;
    letter-spacing: 0px;
    line-height: 30px;
}

.persone_name {
    color: #27417f;
    font-family: 'SFBold';
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    letter-spacing: 1px;
}

.persone_title {
    color: #1e1e1e;
    font-size: 12px;
    font-family: SFRegular;
    letter-spacing: 1px;
    text-align: left;
}

.ReactCollapse--collapse {
    max-width: 800px;
    border-radius: 10px;
    transition: height 500ms;
}

.collapse-rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.main-container {
    margin-top: 4%;
    position: relative !important;
}

.experience-content {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 2%;
}

.deneyimpic1 {
    width: 100%;
    margin-bottom: 3%;
    -webkit-box-shadow: -7px 6px 11px 0px rgba(120, 114, 120, 1);
    -moz-box-shadow: -7px 6px 11px 0px rgba(120, 114, 120, 1);
    box-shadow: -7px 6px 11px 0px rgba(120, 114, 120, 1);
}

.deneyimpic2 {
    width: 100%;
    margin-bottom: 3%;
    -webkit-box-shadow: -7px 6px 11px 0px rgba(120, 114, 120, 1);
    -moz-box-shadow: -7px 6px 11px 0px rgba(120, 114, 120, 1);
    box-shadow: -7px 6px 11px 0px rgba(120, 114, 120, 1);
}

.deneyimtekne {
    animation: gemi 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 3s;
}

.deneyimdalga1 {
    animation: gemi 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 2s;
}

.deneyimdalga2 {
    animation: gemi 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 4s;
}

.experience-desc h4 {
    color: #27417f;
    font-family: 'SFBold';
    font-size: 30px;
    text-align: left;
    letter-spacing: 0px;
}

.experience-desc h5 {
    color: #393939;
    font-family: 'SFRegular';
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
}

.noron1 {
    position: absolute;
    top: 0;
    animation: shake 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 2s;
}

.noron2 {
    position: absolute;
    top: 5%;
    animation: shake2 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 2.2s;

}

.noron3 {
    position: absolute;
    top: 15%;
    animation: shake3 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 2.1s;
}

.noron4 {
    position: absolute;
    top: 25%;
    animation: shake4 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 1s;
}

.noron5 {
    position: absolute;
    top: 30%;
    animation: shake 8s infinite linear;
    transform: scale(0.8);
    animation-direction: alternate;
    animation-duration: 3s;
}

.contact-text {
    color: #243F7D;
    font-family: 'SFBold';
    font-size: 16px;
    text-align: center;
}

.contact-text-ust {
    color: #243F7D;
    font-family: 'SFBold';
    font-size: 20px;
    text-align: center;
}

.sol-ust {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 10%;
}

.sol-alt {
    position: absolute;
    bottom: 0%;
    left: 5%;
    width: 10%;
}

.sag-ust {
    position: absolute;
    top: 4%;
    right: 5%;
    width: 10%;
}

.sag-alt {
    position: absolute;
    bottom: 0%;
    right: 0%;
    width: 10%;
}

.sol-ust-about {
    position: absolute;
    top: 34%;
    left: 0%;
    width: 5%;
}

.sol-alt-about {
    position: absolute;
    bottom: 10%;
    left: 2%;
    width: 5%;
}

.sag-ust-about {
    position: absolute;
    top: 37%;
    right: 2%;
    width: 5%;
}

.sag-alt-about {
    position: absolute;
    bottom: 9%;
    right: 0%;
    width: 5%;
}


@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, 1px) rotate(0deg);
    }

    20% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    30% {
        transform: translate(3px, 1px) rotate(0deg);
    }

    40% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    50% {
        transform: translate(-1px, 1px) rotate(0deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes shake2 {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-2px, 1px) rotate(0deg);
    }

    20% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    30% {
        transform: translate(3px, 1px) rotate(0deg);
    }

    40% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    50% {
        transform: translate(-1px, 1px) rotate(0deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes shake3 {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-2px, 1px) rotate(0deg);
    }

    20% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    30% {
        transform: translate(3px, 1px) rotate(0deg);
    }

    40% {
        transform: translate(2px, 1px) rotate(0deg);
    }

    50% {
        transform: translate(-1px, 1px) rotate(0deg);
    }

    60% {
        transform: translate(-2px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes shake4 {
    0% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    10% {
        transform: translate(-2px, 1px) rotate(0deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(0deg);
    }

    30% {
        transform: translate(3px, 1px) rotate(0deg);
    }

    40% {
        transform: translate(2px, 1px) rotate(0deg);
    }

    50% {
        transform: translate(-1px, 1px) rotate(0deg);
    }

    60% {
        transform: translate(-2px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

@keyframes gemi {
    from {
        transform: translate(0%, 0);
    }

    to {
        transform: translate(-25%, 0);
    }

}

@media screen and (max-width: 1024px) {
    .carousel-item {
        background: transparent;
        height: 65vh !important;
        text-align: center;
        width: 100%;
    }

    .banner1 {
        background-position-x: 42%;
    }
}

@media screen and (max-width: 952px) {
    .carousel-item {
        height: 32vh !important;
    }

    .carousel-item h2 {
        color: white;
        font-size: 40px;
        padding-left: 1%;
        padding-right: 1%;
    }

    .carousel-item h4 {
        color: white;
        font-size: 30px;
    }

    .carousel-item h5 {
        font-size: 18px;
        padding-left: 8%;
        padding-right: 8%;
    }

    .about-content h4 {
        padding-right: 4%;
        padding-left: 4%;
        font-size: 20px;
    }

    .banner-content {
        padding-top: 1%;
    }

    .main-container {
        width: 80% !important;
        left: 8% !important;
    }

    .left-label>h4 {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }

    .right-label>h4 {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }

    .schedular {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 2%;
    }

    .scheduler-time {
        font-size: 20px;
    }

    .scheduler-header {
        font-size: 20px;
    }

    .scheduler-desc {
        font-size: 18px;
    }

    .scheduler-subheader {
        padding-top: 5%;
        font-size: 16px;
    }

    .titles {
        font-size: 18px;
        padding: 0%;
        padding-top: 2%;
    }

    .banner1 {
        background-position-x: 34%;
    }

    .speakers-content {
        font-size: 40px;
    }

    .speakers-banner {
        width: 80%;
    }
}

@media screen and (max-width: 952px)and (orientation: landscape) {
    .carousel-item {
        background: transparent;
        height: 80vh !important;
        text-align: center;
        width: 100%;
    }

    .carousel-item h2 {
        color: white;
        font-size: 35px;
        padding-left: 1%;
        padding-right: 1%;
    }

    .carousel-item h4 {
        color: white;
        line-height: normal;
        font-size: 25px;
    }

    .carousel-item h5 {
        color: white;
        font-size: 18px;
        padding-left: 10%;
        padding-right: 10%;
    }

    .about-content h4 {
        padding-right: 4%;
        padding-left: 4%;
        font-size: 16px;
    }

    .banner-content {
        padding-top: 1%;
        font-size: 34px;
    }

    .main-container {
        width: 80% !important;
        left: 8% !important;
    }

    .left-label>h4 {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }

    .right-label>h4 {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }

    .schedular {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 2%;
    }

    .scheduler-time {
        font-size: 20px;
    }

    .scheduler-header {
        font-size: 20px;
    }

    .scheduler-desc {
        font-size: 18px;
    }

    .scheduler-subheader {
        padding-top: 5%;
        font-size: 16px;
    }

    .titles {
        font-size: 18px;
        padding: 0%;
        padding-top: 2%;
    }

    .about-content h1 {
        font-size: 30px;
    }

    .about-content h2 {
        font-size: 25px;
    }

    .about-content h4 {
        font-size: 18px;
    }
}

@media screen and (max-width: 850px) {
    .carousel-item {
        background: transparent;
        height: 35vh !important;
        text-align: center;
        width: 100%;
    }

    .carousel-item h2 {
        color: white;
        font-size: 48px;
        padding-left: 1%;
        padding-right: 1%;
    }

    .carousel-item h4 {
        color: white;
        line-height: normal;
        font-size: 30px;
    }

    .carousel-item h5 {
        color: white;
        font-size: 25px;
        padding-left: 8%;
        padding-right: 8%;
    }

    .schedular {
        padding-left: 4%;
        padding-right: 4%;
        padding-top: 15%;
    }

    .speaker-name {
        margin-top: 3%;
        font-size: 14px;
    }

    .speaker-title {
        font-size: 12px;
    }

    .main-container {

        height: 40px !important;
        left: 9% !important;
    }

    .switch {
        height: 37px !important;
    }

    .left-label>h4 {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }

    .right-label>h4 {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }

    .schedular {
        padding-top: 8%;
    }

    .sol-alt {
        display: none;
    }

    .sol-ust {
        display: none;
    }

    .sag-alt {
        display: none;
    }

    .sag-ust {
        display: none;
    }

    .sol-alt-about {
        display: none;
    }

    .sol-ust-about {
        display: none;
    }

    .sag-alt-about {
        display: none;
    }

    .sag-ust-about {
        display: none;
    }
}

@media screen and (max-width: 850px)and (orientation: landscape) {
    .carousel-item {
        background: transparent;
        height: 90vh !important;
        text-align: center;
        width: 100%;
    }

    .carousel-item h2 {
        color: white;
        font-size: 35px;
        padding-left: 1%;
        padding-right: 1%;
    }

    .carousel-item h4 {
        color: white;
        line-height: normal;
        font-size: 25px;
    }

    .carousel-item h5 {
        color: white;
        font-size: 18px;
        padding-left: 10%;
        padding-right: 10%;
    }

    .about-content h4 {
        padding-right: 4%;
        padding-left: 4%;
        font-size: 16px;
    }

    .banner-content {
        padding-top: 1%;
    }

    .main-container {
        width: 80% !important;
        left: 8% !important;
    }

    .left-label>h4 {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }

    .right-label>h4 {
        font-size: 14px !important;
        letter-spacing: 1px !important;
    }

    .schedular {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 2%;
    }

    .scheduler-time {
        font-size: 20px;
    }

    .scheduler-header {
        font-size: 20px;
    }

    .scheduler-desc {
        font-size: 18px;
    }

    .scheduler-subheader {
        padding-top: 5%;
        font-size: 16px;
    }

    .titles {
        font-size: 18px;
        padding: 0%;
        padding-top: 2%;
    }
}

@media screen and (max-width: 812px) {}


@media screen and (max-width: 768px) {
    .carousel-item {
        background: transparent;
        height: 35vh !important;
        text-align: center;
        width: 100%;
    }

    .about-content h4 {
        padding-right: 4%;
        padding-left: 4%;
        font-size: 16px;
    }

    .banner-content {
        padding-top: 1%;
        font-size: 30px;
    }

    .schedular {
        padding-top: 4%;
    }

    .main-container {
        width: 80% !important;
        left: 10% !important;
    }

    .banner1 {
        background-position-x: 25%;
        width: 100%;
    }
}

@media screen and (max-width: 768px) and (orientation:landscape) {

    .carousel-item {
        background: transparent;
        height: 80vh !important;
        text-align: center;
        width: 100%;
    }

    .carousel-item h2 {
        color: white;
        font-size: 30px;
        padding-left: 1%;
        padding-right: 1%;
    }

    .carousel-item h4 {
        color: white;
        font-size: 20px;
        padding-left: 15%;
        padding-right: 15%;
    }

    .carousel-item h5 {
        color: white;
        font-size: 15px;
        font-weight: 300;
        padding-left: 10%;
        padding-right: 10%;
    }

    .schedular {
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 15%;
    }

    .scheduler-time {
        font-size: 16px;
    }

    .scheduler-header {
        font-size: 14px;
    }

    .scheduler-desc {
        font-size: 12px;
    }

    .scheduler-subheader {
        padding-top: 5%;
        font-size: 12px;
    }

    .titles {
        font-size: 12px;
        padding: 0%;
        padding-top: 5%;
    }

    .banner-content {
        font-size: 30px;
    }

    .about-content h1 {
        font-size: 18px;
        letter-spacing: -1px;
    }

    .about-content h2 {
        font-size: 16px;
        letter-spacing: 1px;
    }

    .about-content h4 {
        padding-right: 4%;
        padding-left: 4%;
        font-size: 12px;
    }

    .main-container {
        width: 80% !important;
        height: 30px !important;
    }

    .switch {

        height: 30px !important;
    }

    .left-label>h4 {
        font-size: 12px !important;
        letter-spacing: 1px !important;
    }

    .right-label>h4 {
        font-size: 12px !important;
        letter-spacing: 1px !important;
    }

    .schedular {
        padding-top: 5%;
    }

}

@media screen and (max-width: 576px) {
    .carousel-item {
        background: transparent;
        height: 35vh !important;
        text-align: center;
        width: 100%;

    }

    .carousel-item h2 {
        color: white;
        font-size: 24px;
        padding-left: 1%;
        padding-right: 1%;
    }

    .carousel-item-mobile {
        font-size: 18px;
    }

    .carousel-item h4 {
        color: white;
        line-height: normal;
        font-size: 16px;
    }

    .carousel-item h5 {
        color: white;
        font-size: 12px;
        padding-left: 8%;
        padding-right: 8%;
    }

    .banner1 {
        background-position-x: 26%;
        width: 100%;
    }

    .banner-content {
        font-size: 20px;
        padding-top: 1%;
    }

    .about-content {
        padding-top: 5%;
        overflow-x: auto;
    }

    .about-content h1 {
        font-size: 25px;
        letter-spacing: -1px;
    }

    .about-content h2 {
        font-size: 18px;
        letter-spacing: 1px;
    }

    .about-content h4 {
        padding-right: 4%;
        padding-left: 4%;
        font-size: 12px;
    }

    .main-container {
        width: 80% !important;
        left: 8% !important;
    }

    .left-label>h4 {
        font-size: 12px !important;
        letter-spacing: 1px !important;
    }

    .right-label>h4 {
        font-size: 12px !important;
        letter-spacing: 1px !important;
    }

    .schedular {
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 12%;
    }

    .scheduler-time {
        font-size: 10px;
        font-family: 'SFRegular';
    }

    .scheduler-header {
        font-size: 12px;
        font-family: 'SFRegular';
    }

    .scheduler-desc {
        font-size: 10px;
        line-height: 20px;

    }

    .scheduler-subheader {
        padding-top: 5%;
        font-size: 12px;
        font-family: 'SFRegular';
        font-weight: 500;
    }

    .collapse-icon {
        width: 100%;
    }

    .titles {
        font-size: 10px;
        padding: 0%;
        padding-top: 5%;
        line-height: 12px;
    }

    .persone_name {
        font-size: 12px;
    }

    .persone_title {
        font-size: 10px;
    }

    .speaker-name {
        margin-top: 3%;
        font-size: 12px;
    }

    .speaker-title {
        font-size: 8px;
    }

    .speaker-name {
        font-size: 10px;
    }
    .reis {
        padding-left: 0%;
        padding-right: 0%;
    }
    .spekars-footer {
        height: 65px;
    }

    .main-container {

        height: 30px !important;
        left: 9% !important;
    }

    .switch {
        height: 28px !important;
    }

    .noron1 {
        top: 0;
    }

    .noron2 {
        top: 15%;

    }

    .noron3 {
        top: 35%;
    }

    .noron4 {
        top: 55%;
    }

    .noron5 {
        top: 75%;
    }

    .brain-image {
        margin-left: 30%;
        margin-bottom: 10%;
    }

    .deneyimtekne_image {
        margin-left: 10%;
        margin-bottom: 10%;
    }

    .experience-desc h4 {
        font-size: 28px;
    }

    .speakers-banner {
        margin-top: 10%;
        width: 80%;
    }

    .banner1-mobile {
        background-position-x: 2%;
        background-size: cover;
        height: 100%;
    }

    .speakers-content {
        font-size: 24px;
    }

    .experience-content {
        padding-left: 1%;
        padding-right: 1%;
    }

}


@media screen and (max-width: 411px) {
    .carousel-item {
        background: transparent;
        height: 35vh !important;
        text-align: center;
        width: 100%;
    }

    .carousel-item h2 {
        color: white;
        font-size: 30px;
        padding-left: 1%;
        padding-right: 1%;
    }

    .carousel-item h4 {
        color: white;
        line-height: normal;
        font-size: 10px;
    }

    .carousel-item h5 {
        color: white;
        font-size: 12px;
        padding-left: 8%;
        padding-right: 8%;
    }

}

@media screen and (max-width: 393px) {

    .carousel-item {
        background: transparent;
        height: 35vh !important;
        text-align: center;
        width: 100%;
    }

    .carousel-item h2 {
        color: white;
        font-size: 20px;
        padding-left: 1%;
        padding-right: 1%;
        padding-top: 10%;
    }

    .carousel-item h4 {
        color: white;
        line-height: normal;
        font-size: 14px;
    }

    .carousel-item h5 {
        color: white;
        font-size: 13px;
    }

    .main-container {

        height: 30px !important;
        left: 9% !important;
    }

    .switch {
        height: 27px !important;
    }

    .contact-text-ust {
        font-size: 16px;
    }

    .titles {

        line-height: 15px;
    }

    .experience-desc h4 {
        font-size: 26px;
    }

    .carousel-extra {
        font-size: 16px !important;
        line-height: 30px !important;
    }

    .scheduler-time {
        font-size: 10px;
        text-align: center;
    }

    .speaker-title {
        font-size: 6px;
    }

}

@media screen and (max-width: 375px) {

    .carousel-item {
        background: transparent;
        height: 38vh !important;
        text-align: center;
        width: 100%;
    }

    .carousel-item h2 {
        color: white;
        font-size: 24px;
        padding-left: 1%;
        padding-right: 1%;
    }

    .carousel-item h4 {
        color: white;
        font-size: 12px;
    }

    .carousel-item h5 {
        color: white;
        font-size: 12px;
        padding-left: 8%;
        padding-right: 8%;
    }

    .banner-content {
        font-size: 20px;
        padding-top: 1%;
    }

    .banner1 {
        background-position-x: 30%;
    }

    .about-content {
        padding-top: 2%;
    }

    .about-content h1 {
        font-size: 25px;
        letter-spacing: -1px;
    }

    .about-content h2 {
        font-size: 18px;
        letter-spacing: 1px;
    }

    .about-content h4 {
        padding-right: 4%;
        padding-left: 4%;
        font-size: 14px;
    }

    .main-container {
        width: 80% !important;
        left: 8% !important;
    }

    .left-label>h4 {
        font-size: 12px !important;
        letter-spacing: 1px !important;
        padding-bottom: 2% !important;
    }

    .right-label>h4 {
        font-size: 12px !important;
        letter-spacing: 1px !important;
        padding-bottom: 2% !important;
    }

    .schedular {
        padding-left: 3%;
        padding-right: 3%;
        padding-top: 15%;
    }

    .scheduler-time {
        font-size: 10px;
    }

    .scheduler-header {
        font-size: 10px;
    }

    .scheduler-desc {
        font-size: 10px;
    }

    .scheduler-subheader {
        padding-top: 5%;
        font-size: 12px;
    }

    .titles {
        font-size: 10px;
        padding: 0%;
        padding-top: 5%;
    }

    .persone_name {
        font-size: 12px;
    }

    .persone_title {
        font-size: 10px;
    }

    .speaker-name {
        margin-top: 3%;
        font-size: 13px;
    }

    .speaker-title {
        font-size: 8px;
    }

    .experience-desc h4 {
        font-size: 26px;
    }

    .noron1 {
        top: 0;
    }

    .noron2 {
        top: 15%;

    }

    .noron3 {
        top: 35%;
    }

    .noron4 {
        top: 55%;
    }

    .noron5 {
        top: 75%;
    }

    .brain-image {
        margin-left: 30%;
        margin-bottom: 10%;
    }

    .deneyimtekne_image {
        margin-left: 15%;
        margin-bottom: 10%;
    }

    .experience-desc h4 {
        font-size: 24px;
    }


}